<template>
  <div>
    <YoutubeHeader />
    <Tabs v-model="selectedTab" />
    <TabContent show-banner :selected-tab="selectedTab" />
  </div>
</template>

<script>
import YoutubeHeader from '@/components/YoutubeHeader.vue';
import Tabs from '@/components/ChargeYourEvTabs.vue';
import TabContent from '@/components/ChargeYourEvTabContent.vue';

export default {
  name: 'ChargingYourEv',
  components: {
    YoutubeHeader,
    Tabs,
    TabContent,
  },
  metaInfo: {
    title: `How to Charge an Electric Car | Electrify America`,
    meta: [
      {
        name: 'description',
        content: `Learn how to charge your electric car in a few easy steps with Electrify America. Simply choose your power level, plug in your connector & start charging.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/charging-your-ev/' }],
  },
  data() {
    return {
      selectedTab: 'InTheApp',
    };
  },
};
</script>
