<template>
  <section
    :id="`tabpanel-${selectedTab}`"
    class="tab-content-container"
    role="tabpanel"
    :aria-labelledby="`tab-${selectedTab}`"
    tabindex="0"
  >
    <div v-if="showBanner" id="tab-content-banner">tab-content-banner</div>
    <div v-if="['InTheApp', 'InYourCar'].includes(selectedTab)" class="mobile-app-banner">
      <div class="mobile-app-icon-container">
        <div class="mobile-app-icon">
          <img loading="lazy" alt="Electrify America Logo" src="../assets/images/ChargingYourEv/MobileAppIcon.png" />
        </div>
        <div v-if="selectedTab == 'InTheApp'" class="mobile-app-text">
          Get the <strong>Electrify America mobile app</strong>
        </div>
        <div v-else class="mobile-app-text">
          The Electrify America mobile app supports <strong>Apple CarPlay</strong> and <strong>Android Auto</strong> in
          compatible vehicles.
        </div>
      </div>
      <div class="app-store-icons">
        <a href="https://apps.apple.com/us/app/electrify-america/id1458030456" rel="noopener" target="_blank"
          ><img loading="lazy" alt="Download on the App Store" src="../assets/images/ChargingYourEv/AppStore.jpg"
        /></a>
        <a
          href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
          rel="noopener"
          target="_blank"
          ><img loading="lazy" alt="Get it on Google Play" src="../assets/images/ChargingYourEv/GooglePlay.jpg"
        /></a>
      </div>
    </div>
    <div class="table-of-contents">
      <h2>Steps</h2>
      <ol>
        <li v-for="(content, index) in tabsData[selectedTab]" :key="index">
          <a :href="`#${content.value}`" v-text="content.text" />
        </li>
      </ol>
    </div>
    <button id="cta-button" v-if="selectedTab == 'AtTheCharger'">
      <img loading="lazy" alt="Phone Icon" src="../assets/images/ChargingYourEv/PhoneIcon.png" />CONTACT US
    </button>
    <div v-if="selectedTab == 'AtTheCharger'" id="AtTheCharger">
      <div class="steps-container">
        <div id="Park" class="step">
          <div class="label-container">
            <span class="label-number"> 1 </span>
            <h3>Park</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/AtTheCharger/Step1@3x.jpg" />
              <img
                loading="lazy"
                alt="An electric vehicle charging"
                src="../assets/images/ChargingYourEv/AtTheCharger/Step1@2x.jpg"
              />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li>
                <p>
                  Park on the side where your vehicle’s charging port is located. Notice the connector type indicated on
                  the parking spot.
                </p>
              </li>
              <li><p>Park close enough to the charger so the connector does not overextend when plugged in.</p></li>
            </ul>
          </div>
        </div>
        <div id="CheckYourConnectorType" class="step">
          <div class="label-container">
            <span class="label-number"> 2 </span>
            <h3>
              Check your <br v-if="isPhone" />
              connector type and power level
            </h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/AtTheCharger/Step2@3x.jpg" />
              <img
                loading="lazy"
                alt="View of the charger screen"
                src="../assets/images/ChargingYourEv/AtTheCharger/Step2@2x.jpg"
              />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li>
                <p>
                  View the charger’s screen to ensure the connector is compatible* with your vehicle’s charging port.
                </p>
                <ul style="padding-left: 25px; margin-top: 10px">
                  <li>
                    <p>
                      Our CCS connectors offer two charging speeds: <strong>Hyper-Fast</strong> (up to 350 kW) and
                      <strong>Ultra-Fast</strong> (up to 150 kW).
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <p>For reference, your charging port should resemble the icon at the top of the charger’s labels.</p>
              </li>
              <div class="small-text">
                *Not all EVs are capable of charging at the charger’s maximum power level. Check with your vehicle’s
                manufacturer to find out your vehicle’s charging capabilities.
              </div>
            </ul>
          </div>
        </div>
        <div id="PlugInTheConnectorUntilItClicks" class="step">
          <div class="label-container">
            <span class="label-number"> 3 </span>
            <h3>Plug in the connector until it clicks</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/AtTheCharger/Step3@3x.jpg" />
              <img
                loading="lazy"
                alt="Close up of plug and car's charging port"
                src="../assets/images/ChargingYourEv/AtTheCharger/Step3@2x.jpg"
              />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li>
                <p>Remove the connector from the charger with two hands.</p>
              </li>
              <li>
                <p>
                  Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
                </p>
              </li>
              <li>
                <p>
                  Release the button on top of the connector. You will hear a <strong>‘click’</strong> when your vehicle
                  is fully connected.
                </p>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=oTYcvec2VHE"
                  class="connector-tips"
                  rel="noopener"
                  target="_blank"
                  style="display: flex; align-items: center"
                >
                  Charger connector tips
                  <span style="margin-left: 10px"
                    ><img
                      loading="lazy"
                      alt="Youtube video icon"
                      src="../assets/images/ChargingYourEv/Video.png" /></span
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div id="Pay" class="step">
          <div class="label-container">
            <span class="label-number"> 4 </span>
            <h3>Pay</h3>
          </div>
        </div>
        <div id="PlugInTheConnectorUntilItClicks" class="stepCombined">
          <div v-if="isPhone" class="start-a-charger">
            <accordion header-id="Guests">
              <accordion-item title="Guests" :isChevron="true">
                <div class="scanning-images">
                  <img loading="lazy" alt="" src="../assets/images/ChargingYourEv/InTheApp/card-reader.webp" />
                  <p>Card Reader</p>
                </div>
                <img loading="lazy" alt="" src="../assets/images/ChargingYourEv/InTheApp/guestTab.webp" />
                <h5>To pay as a guest...</h5>
                <p>Pricing will appear on the charger’s screen.</p>
                <ul>
                  <li>Remove the connector from the charger with two hands.</li>
                  <li>
                    Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
                  </li>
                  <li>
                    Release the button on top of the connector. You will hear a ‘click’ when your vehicle is fully
                    connected.
                  </li>
                  <li>
                    Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
                  </li>
                  <li>
                    Insert, tap, or swipe your credit card. Or use your mobile wallet* and tap your phone against the
                    credit card reader.
                  </li>
                </ul>
                <small> *Compatible with Apple, Google, and Samsung Pay.</small>
              </accordion-item>
            </accordion>
          </div>
          <div v-else class="start-a-charger">
            <h4>Guests</h4>
            <div class="scanning-images">
              <img
                loading="lazy"
                alt="Mobile app user interface"
                src="../assets/images/ChargingYourEv/InTheApp/card-reader.webp"
              />
              <p>Card Reader</p>
            </div>
            <img
              loading="lazy"
              alt="Mobile app user interface"
              src="../assets/images/ChargingYourEv/InTheApp/guestTab.webp"
            />
            <h5>To pay as a guest...</h5>
            <p>Pricing will appear on the charger’s screen.</p>
            <ul>
              <li>Remove the connector from the charger with two hands.</li>
              <li>
                Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
              </li>
              <li>
                Release the button on top of the connector. You will hear a ‘click’ when your vehicle is fully
                connected.
              </li>
              <li>
                Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
              </li>
              <li>
                Insert, tap, or swipe your credit card. Or use your mobile wallet* and tap your phone against the credit
                card reader.
              </li>
            </ul>
            <small> *Compatible with Apple, Google, and Samsung Pay.</small>
          </div>
          <div class="vertical-line-section">
            <hr v-if="isPhone" />
            <div v-else class="vl"></div>
            <div class="or">OR</div>
            <hr v-if="isPhone" />
            <div v-else class="vl"></div>
          </div>
          <div v-if="isPhone" class="start-a-charger">
            <accordion header-id="Members">
              <accordion-item title="Members" :isChevron="true">
                <div class="scanning-images">
                  <img loading="lazy" alt="" src="../assets/images/ChargingYourEv/InTheApp/LabelSourceArt.webp" />
                  <p>Contactless reader</p>
                </div>
                <img loading="lazy" alt="" src="../assets/images/ChargingYourEv/InTheApp/membertab.webp" />
                <h5>To pay as a member...</h5>
                <p>Pricing will appear in the app.</p>
                <ul>
                  <li>Remove the connector from the charger with two hands.</li>
                  <li>
                    Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
                  </li>
                  <li>
                    Release the button on top of the connector. You will hear a ‘click’ when your vehicle is fully
                    connected.
                  </li>
                  <li>
                    Access your digital membership pass in your mobile wallet. Then tap your phone against the
                    contactless reader.
                  </li>
                  <li>
                    Slide your finger across your phone screen to start a charge. It may take a few minutes for your
                    vehicle to start charging.
                  </li>
                  <li class="video-link">
                    Or use
                    <a class="link" href="https://www.youtube.com/watch?v=zKiHvRnzUTI" target="_blank">
                      <strong>Swipe to Start</strong
                      ><img loading="lazy" alt src="../assets/images/ChargingYourEv/InTheApp/ Video.webp"
                    /></a>
                    to start a charge in the app.
                  </li>
                  <li>
                    Pass, Pass+, or Premium Offer members can pay for their charging session with the Electrify America
                    app or select vehicle manufacturer apps.
                  </li>
                </ul>
                <div class="tip">
                  <img loading="lazy" src="../assets/images/WhatToExpect/icon-star.png" alt="" />
                  <p>
                    <strong> Pass+ members save about 25% on charging. Download our app to become a member.</strong>
                  </p>
                </div>
                <div class="partner-page__cta">
                  <a
                    rel="noopener"
                    target="_blank"
                    class="partner-page__cta__link"
                    href="https://itunes.apple.com/us/app/electrify-america/id1458030456?mt=8"
                    ><img
                      loading="lazy"
                      src="@/assets/images/Global/app-store-logo@2x.webp"
                      alt="Download on the App Store"
                  /></a>
                  <a
                    rel="noopener"
                    target="_blank"
                    class="partner-page__cta__link"
                    href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
                    ><img
                      loading="lazy"
                      src="@/assets/images/Global/google-play-badge@2x.webp"
                      alt="Get it on Google Play"
                  /></a>
                </div>
              </accordion-item>
            </accordion>
          </div>
          <div v-else class="start-a-charger">
            <h4>Members</h4>
            <div class="scanning-images">
              <img
                loading="lazy"
                alt="Mobile app user interface"
                src="../assets/images/ChargingYourEv/InTheApp/LabelSourceArt.webp"
              />
              <p>Contactless reader</p>
            </div>
            <img
              loading="lazy"
              alt="Mobile app user interface"
              src="../assets/images/ChargingYourEv/InTheApp/membertab.webp"
            />
            <h5>To pay as a member...</h5>
            <p>Pricing will appear in the app.</p>
            <ul>
              <li>Remove the connector from the charger with two hands.</li>
              <li>
                Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
              </li>
              <li>
                Release the button on top of the connector. You will hear a ‘click’ when your vehicle is fully
                connected.
              </li>
              <li>
                Access your digital membership pass in your mobile wallet. Then tap your phone against the contactless
                reader.
              </li>
              <li>
                Slide your finger across your phone screen to start a charge. It may take a few minutes for your vehicle
                to start charging.
              </li>
              <li class="video-link">
                Or use
                <a class="link" href="https://www.youtube.com/watch?v=zKiHvRnzUTI" target="_blank">
                  <strong>Swipe to Start</strong
                  ><img loading="lazy" alt src="../assets/images/ChargingYourEv/InTheApp/ Video.webp"
                /></a>
                to start a charge in the app.
              </li>
              <li>
                Pass, Pass+, or Premium Offer members can pay for their charging session with the Electrify America app
                or select vehicle manufacturer apps.
              </li>
            </ul>
            <div class="tip">
              <img loading="lazy" src="../assets/images/WhatToExpect/icon-star.png" alt="" />
              <p>
                <strong> Pass+ members save about 25% on charging. Download our app to become a member.</strong>
              </p>
            </div>
            <div class="partner-page__cta">
              <a
                rel="noopener"
                target="_blank"
                class="partner-page__cta__link"
                href="https://itunes.apple.com/us/app/electrify-america/id1458030456?mt=8"
                ><img
                  loading="lazy"
                  src="@/assets/images/Global/app-store-logo@2x.webp"
                  alt="Download on the App Store"
              /></a>
              <a
                rel="noopener"
                target="_blank"
                class="partner-page__cta__link"
                href="https://play.google.com/store/apps/details?id=com.ea.evowner&rdid=com.ea.evowner"
                ><img loading="lazy" src="@/assets/images/Global/google-play-badge@2x.webp" alt="Get it on Google Play"
              /></a>
            </div>
          </div>
        </div>
        <div id="StartYourCharge" class="step">
          <div class="label-container">
            <span class="label-number"> 5 </span>
            <h3>Start your charge</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/AtTheCharger/Step5@3x.jpg" />
              <img
                loading="lazy"
                alt="User Interface for charging screen"
                src="../assets/images/ChargingYourEv/AtTheCharger/Step5@2x.jpg"
              />
            </picture>
          </div>
          <div class="text-container">
            <p class="header">
              Your vehicle may take a few minutes to start charging. Session details will appear on the charger’s
              screen.
            </p>
            <ul>
              <li>
                <p>For updates about your session and to receive a text receipt:</p>
                <ul style="padding-left: 22px">
                  <li>
                    <router-link :to="{ name: 'mobile-app-en' }" class="link"
                      ><strong>Download the Electrify America app</strong></router-link
                    >
                  </li>
                  <li>Or enter your cell phone's number on the charger screen</li>
                </ul>
              </li>
              <li>To manually end your charging session, press <strong>Stop</strong>.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="selectedTab == 'InTheApp'" id="InTheApp">
      <div class="steps-container">
        <div id="SelectAChargingStation" class="step">
          <div class="label-container">
            <span class="label-number"> 1 </span>
            <h3>Select a charging station</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/InTheApp/Step1@3x.jpg" />
              <img
                loading="lazy"
                alt="Mobile app user interface"
                src="../assets/images/ChargingYourEv/InTheApp/Step1@2x.jpg"
              />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li><p>After you’ve parked at the charger, select your charging station in the app.</p></li>
            </ul>
          </div>
        </div>
        <div id="SelectChargingNumber" class="step">
          <div class="label-container">
            <span class="label-number"> 2 </span>
            <h3>Select charger number</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/InTheApp/Step2@3x.jpg" />
              <img
                loading="lazy"
                alt="Mobile app user interface"
                src="../assets/images/ChargingYourEv/InTheApp/Step2@2x.jpg"
              />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li>
                <p>Select charger number in the app, under the <strong>Start a Charge</strong> section.</p>
              </li>
              <li>
                <p>The charger’s number can be found <strong>above the charger screen</strong>.</p>
              </li>
            </ul>
          </div>
        </div>
        <div id="SwipeToStart" class="step">
          <div class="label-container">
            <span class="label-number"> 3 </span>
            <h3>Start a charge</h3>
          </div>
        </div>
        <div id="PlugInTheConnectorUntilItClicks" class="stepCombined">
          <div v-if="isPhone" class="start-a-charger">
            <accordion header-id="Swipe to start first">
              <accordion-item title="Swipe to start first" :isChevron="true">
                <img
                  loading="lazy"
                  alt="Mobile app user interface"
                  src="../assets/images/ChargingYourEv/InTheApp/Image4-two.webp"
                />
                <h5>To swipe to start first...</h5>
                <ul>
                  <li>Confirm your payment method or selected membership plan.</li>
                  <li>Slide your finger across your phone screen to start a charge.</li>
                  <li>Remove the connector from the charger with two hands.</li>
                  <li>
                    Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
                  </li>
                  <li>
                    Release the button on top of the connector. You will hear a ‘click’ when your vehicle is fully
                    connected. It may take a few minutes for your vehicle to start charging.
                  </li>
                  <li>To receive detailed updates about your session, tap <strong>One-time notifications.</strong></li>
                </ul></accordion-item
              >
            </accordion>
          </div>
          <div v-else class="start-a-charger">
            <h4>Swipe to start first</h4>
            <img
              loading="lazy"
              alt="Mobile app user interface"
              src="../assets/images/ChargingYourEv/InTheApp/Image4-two.webp"
            />
            <h5>To swipe to start first...</h5>
            <ul>
              <li>Confirm your payment method or selected membership plan.</li>
              <li>Slide your finger across your phone screen to start a charge.</li>
              <li>Remove the connector from the charger with two hands.</li>
              <li>
                Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
              </li>
              <li>
                Release the button on top of the connector. You will hear a ‘click’ when your vehicle is fully
                connected. It may take a few minutes for your vehicle to start charging.
              </li>
              <li>To receive detailed updates about your session, tap <strong>One-time notifications.</strong></li>
            </ul>
          </div>
          <div class="vertical-line-section">
            <hr v-if="isPhone" />
            <div v-else class="vl"></div>
            <div class="or">OR</div>
            <hr v-if="isPhone" />
            <div v-else class="vl"></div>
          </div>
          <div v-if="isPhone" class="start-a-charger">
            <accordion header-id="Plug in first">
              <accordion-item title="Plug in first" :isChevron="true">
                <img
                  loading="lazy"
                  alt="Mobile app user interface"
                  src="../assets/images/ChargingYourEv/InTheApp/Image4.webp" />
                <h5>To plug in first...</h5>
                <ul>
                  <li>Remove the connector from the charger with two hands.</li>
                  <li>
                    Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
                  </li>
                  <li>
                    Release the button on top of the connector. You will hear a ‘click’ when your vehicle is fully
                    connected.
                  </li>
                  <li>In the app, select your preferred charger.</li>
                  <li>
                    Slide your finger across your phone screen to start a charge. It may take a few minutes for your
                    vehicle to start charging.
                  </li>
                  <li class="video-link">
                    <a class="link" href="https://www.youtube.com/watch?v=oTYcvec2VHE" target="_blank">
                      <strong>Charger connector tips </strong
                      ><img loading="lazy" alt src="../assets/images/ChargingYourEv/InTheApp/ Video.webp"
                    /></a>
                  </li></ul
              ></accordion-item>
            </accordion>
          </div>
          <div v-else class="start-a-charger">
            <h4>Plug in first</h4>
            <img
              loading="lazy"
              alt="Mobile app user interface"
              src="../assets/images/ChargingYourEv/InTheApp/Image4.webp"
            />
            <h5>To plug in first...</h5>
            <ul>
              <li>Remove the connector from the charger with two hands.</li>
              <li>
                Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
              </li>
              <li>
                Release the button on top of the connector. You will hear a ‘click’ when your vehicle is fully
                connected.
              </li>
              <li>In the app, select your preferred charger.</li>
              <li>
                Slide your finger across your phone screen to start a charge. It may take a few minutes for your vehicle
                to start charging.
              </li>
              <li class="video-link">
                <a class="link" href="https://www.youtube.com/watch?v=oTYcvec2VHE" target="_blank">
                  <strong>Charger connector tips </strong
                  ><img loading="lazy" alt src="../assets/images/ChargingYourEv/InTheApp/ Video.webp"
                /></a>
              </li>
            </ul>
          </div>
        </div>
        <div id="Charge" class="step">
          <div class="label-container">
            <span class="label-number"> 4 </span>
            <h3>Charge</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/InTheApp/Step5@3x.jpg" />
              <img loading="lazy" alt="User interface" src="../assets/images/ChargingYourEv/InTheApp/Step5@2x.jpg" />
            </picture>
          </div>
          <div class="text-container">
            <p>
              Once you’ve initiated your charge, session details will appear at the top of your phone’s screen and on
              the charger.
            </p>
            <ul>
              <li>
                <p>
                  To manually end your charging session, press <strong>Stop</strong> in the app. Your preferred payment
                  will be charged at the end of your session.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="selectedTab == 'InYourCar'" id="InYourCar">
      <div class="steps-container">
        <div id="Park" class="step">
          <div class="label-container">
            <span class="label-number"> 1 </span>
            <h3>Park</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/AtTheCharger/Step1@3x.jpg" />
              <img
                loading="lazy"
                alt="An electric vehicle charging"
                src="../assets/images/ChargingYourEv/AtTheCharger/Step1@2x.jpg"
              />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li>
                <p>
                  Park on the side where your vehicle’s charging port is located. Notice the connector type indicated on
                  the parking spot.
                </p>
              </li>
              <li><p>Park close enough to the charger so the connector does not overextend when plugged in.</p></li>
            </ul>
          </div>
        </div>
        <div id="SelectAChargingStation" class="step">
          <div class="label-container">
            <span class="label-number"> 2 </span>
            <h3>Select a charging station</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/InYourCar/Step2.png" />
              <img
                loading="lazy"
                alt="An electric vehicle charging"
                src="../assets/images/ChargingYourEv/InYourCar/Step2.png"
              />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li><p>Select your current charging station in the Electrify America app.</p></li>
              <li><p>You can navigate to a station using Apple CarPlay or Android Auto.</p></li>
            </ul>
            <p class="small-text">*You should only operate Apple CarPlay or Android Auto while parked.</p>
          </div>
        </div>
        <div id="SelectChargingNumber" class="step">
          <div class="label-container">
            <span class="label-number"> 3 </span>
            <h3>Select charger number</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/InYourCar/Step3.jpg" />
              <img loading="lazy" alt="User interface" src="../assets/images/ChargingYourEv/InYourCar/Step3.jpg" />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li><p>Select your charger’s number using Apple CarPlay or Android Auto.</p></li>
              <li>
                <p>Charger number can be found at the station, <strong>above the charger screen</strong>.</p>
              </li>
            </ul>
          </div>
        </div>
        <div id="TapStartACharge" class="step">
          <div class="label-container">
            <span class="label-number"> 4 </span>
            <h3>Tap "Start Charge"</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/InYourCar/Step4.jpg" />
              <img loading="lazy" alt="User interface" src="../assets/images/ChargingYourEv/InYourCar/Step4.jpg" />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li><p>You must select a membership plan in the mobile app before you can start a charge.</p></li>
              <li><p>Your preferred payment will be charged at the end of your session.</p></li>
              <li>
                <p>
                  To manually end your charging session, press the <strong>Stop</strong> button on your car’s screen.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div id="PlugIn" class="step">
          <div class="label-container">
            <span class="label-number"> 5 </span>
            <h3>Start a charge</h3>
          </div>
          <div class="image-container">
            <picture>
              <source media="(min-width:750px)" srcset="../assets/images/ChargingYourEv/AtTheCharger/Step3@3x.jpg" />
              <img
                loading="lazy"
                alt="Close up of plug and car's charging port"
                src="../assets/images/ChargingYourEv/AtTheCharger/Step3@2x.jpg"
              />
            </picture>
          </div>
          <div class="text-container">
            <ul>
              <li><p>Remove the connector from the charger with two hands.</p></li>
              <li>
                <p>
                  Press and hold the button on top of the connector as you plug it into your vehicle’s charging port.
                </p>
              </li>
              <li>
                <p>
                  Release the button on top of the connector. You will hear a <strong>‘click’</strong> when your vehicle
                  is fully connected. Your vehicle may take a few minutes to start charging.
                </p>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=oTYcvec2VHE"
                  rel="noopener"
                  target="_blank"
                  class="connector-tips"
                  style="display: flex; align-items: center"
                >
                  Charger connector tips
                  <span style="margin-left: 10px"
                    ><img
                      loading="lazy"
                      alt="Youtube video icon"
                      src="../assets/images/ChargingYourEv/Video.png" /></span
                ></a>
              </li>
              <li>
                <p>
                  <strong>Session details</strong> will appear in the Electrify America mobile app or on the charger’s
                  screen.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="trademark">
        Apple CarPlay is a trademark of Apple, Inc. Android Auto is a trademark of Google LLC.
      </div>
    </div>
    <div v-else>Select a tab</div>
  </section>
</template>

<script>
import Accordion from './Accordion/Accordion.vue';
import AccordionItem from './Accordion/AccordionItem.vue';

export default {
  components: {
    Accordion,
    AccordionItem,
  },
  name: 'TabContent',
  props: ['selectedTab', 'showBanner'],
  data() {
    return {
      tabsData: {
        AtTheCharger: [
          { text: 'Park', value: 'Park' },
          { text: 'Check your connector type and power level', value: 'CheckYourConnectorType' },
          { text: 'Plug in the connector until it clicks', value: 'PlugInTheConnectorUntilItClicks' },
          { text: 'Pay', value: 'Pay' },
          { text: 'Start your charge', value: 'StartYourCharge' },
        ],
        InTheApp: [
          { text: 'Select a charging station', value: 'SelectAChargingStation' },
          { text: 'Select charger number', value: 'SelectChargingNumber' },
          { text: 'Start a charge', value: 'SwipeToStart' },
          { text: 'Charge', value: 'Charge' },
        ],
        InYourCar: [
          { text: 'Park', value: 'Park' },
          { text: 'Select a charging station', value: 'SelectAChargingStation' },
          { text: 'Select charging number', value: 'SelectChargingNumber' },
          { text: 'Tap "Start charge"', value: 'TapStartACharge' },
          { text: 'Plug in', value: 'PlugIn' },
        ],
      },
    };
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-content-container {
  padding: 20px 0;
  padding-bottom: 72px;
  @media (min-width: 750px) {
    padding: 80px 20px;
    max-width: 855px;
    margin: 0 auto;
  }
}
.table-of-contents {
  padding: 0 30px;
  max-width: 955px;
  margin: 0 auto;
  @media (min-width: 750px) {
    padding-bottom: 50px;
  }
  h2 {
    font-size: 16px;
    line-height: 1;
    margin: 3px 0 25px;
    font-weight: 600;
    @media (min-width: 750px) {
      font-size: 32px;
      font-weight: 600;
    }
  }
  ol {
    padding: 0 18px;
    margin: 0;
    li {
      padding-bottom: 13px;
      font-size: 16px;
      font-weight: $fw-medium;
      a {
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: 1px;
      }
    }
  }
}
.steps-container {
  max-width: 950px;
  margin: 0 auto;
  .step {
    margin-bottom: 50px;
    @media (min-width: 750px) {
      display: grid;
      grid-template-columns: 390px 1fr;
      grid-gap: 0 60px;
    }
  }
  .stepCombined {
    margin-bottom: 50px;
    margin-top: -15px;
    .partner-page__cta {
      flex-direction: row !important;
      margin: 0;
      margin-left: 20px;
      &__link {
        margin-bottom: 0px;
        margin-top: 20px;
      }
      img {
        height: 33px;
        margin: 0px;
      }
    }
    .scanning-images {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      height: 150px;
      box-sizing: border-box;
      img {
        width: 120px !important;
        margin-right: 20px;
      }
    }
    @media (min-width: 750px) {
      display: grid;
      grid-template-columns: 1fr 30px 1fr;
      grid-gap: 0 30px;
    }
    .tip {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      img {
        width: 30px !important;
        height: 30px;
        margin: 0;
      }
      p {
        margin: 0;
        margin-left: 15px;
      }
    }

    .start-a-charger {
      background: $c-secondary-background;
      border-radius: 8px;
      @media (min-width: 750px) {
        padding: 31px;
      }
      @media (max-width: 750px) {
        margin: 31px;
      }
      img {
        width: 100%;
        margin-bottom: 20px;
      }
      h2,
      h5 {
        padding: 0;
        margin: 0;
      }
      ul {
        padding-left: 20px;
      }
      li {
        margin-bottom: 20px;
        line-height: 28px;
      }
      li:last-child {
        margin-bottom: 0px;
      }
      .video-link {
        img {
          width: 20px;
          margin-bottom: 0;
          margin-left: 10px;
        }
      }
      h4 {
        margin-bottom: 31px;
        font-weight: $fw-medium;
        text-align: center;
      }
    }
    .vertical-line-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 750px) {
        flex-direction: row;
        margin: -20px 15px;
      }
    }
    hr {
      width: 44%;
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #e2e5ed;
    }
    hr:first-child {
      margin-left: 20px;
    }
    hr:last-child {
      margin-right: 20px;
    }
    .vl {
      height: 50%;
      border-left: 3px solid #e2e5ed;
    }
    .or {
      background: $c-primary;
      width: 30px;
      height: 30px;
      margin: 10px 0px;
      border-radius: 100%;
      text-align: center;
      padding: 5px;
      color: $c-primary-background;
      font-weight: $fw-medium;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 750px) {
        margin: 0;
      }
    }
  }
}

.label-container {
  display: flex;
  align-items: flex-start;
  padding: 0 20px;
  margin-bottom: 30px;

  @media (min-width: 750px) {
    grid-column: 1/2;
    grid-row: 1/2;
    padding: 0;
    margin-bottom: 0;
    min-width: 395px;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    letter-spacing: 0.48px;
    color: #0a124a;
    @media (min-width: 750px) {
      font-size: 32px;
    }
  }
  .label-number {
    align-self: flex-start;
    margin-right: 20px;
    font-size: 16px;
    background-color: #0a124a;
    color: white;
    padding: 4px 12px;
    border-radius: 21px;
    font-weight: 600;
    @media (min-width: 750px) {
      align-self: auto;
    }
  }
}

.text-container {
  margin-top: 15px;
  @media (min-width: 750px) {
    grid-column: 1/2;
    grid-row: 2/3;
    margin-top: 0;
  }
  b {
    font-weight: $fw-medium;
  }
  .small-text {
    margin-top: 20px;
    color: black;
    font-size: 14px;
    padding: 0 20px;
  }

  p {
    color: black;
  }
  ul {
    list-style: disc;
    padding: 0 45px;
    margin: 0;
    ::marker {
      font-size: 0.7em;
    }
    @media (min-width: 750px) {
      padding: 0 0 0 70px;
    }
    li {
      margin-bottom: 15px;
      color: black;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        font-size: 16px;
        color: #000000;
        margin: 0;
      }
    }
  }
}

.image-container {
  @media (min-width: 750px) {
    grid-column: 2/3;
    grid-row: 1/3;
    max-width: 465px;
    justify-self: end;
    align-self: center;
  }
  img {
    width: 100%;
  }
}

.connector-tips {
  text-decoration: underline;
  color: black;
  font-weight: $fw-medium;
}

#cta-button {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  font-size: 14px;
  background-color: #0a124a;
  color: white;
  border-radius: 30px;
  border: 3px solid #97cce8;
  margin-left: auto;
  img {
    margin-right: 10px;
  }
  @media (min-width: 750px) {
    display: none;
  }
}

#AtTheCharger {
  #Pay {
    @media (min-width: 750px) {
      grid-template-columns: 1fr 1fr;
      .label-container {
        grid-column: 1/3;
        grid-row: 1/2;
      }
      .image-container,
      .text-container {
        grid-column: auto;
        grid-row: auto;
      }
      .text-container {
        ul {
          padding-left: 30px;
        }
      }
      #guest-member-image {
        grid-column: 1/3;
        grid-row: 2/3;
        max-width: 955px;
      }
      #guest-image {
        grid-column: 1/2;
        grid-row: 3/4;
      }
      #member-image {
        grid-column: 2/3;
        grid-row: 3/4;
      }
    }
    #guest-image,
    #member-image {
      margin-top: 20px;
      padding: 0 20px;
      max-width: 600px;
      margin: 20px auto;
      @media (min-width: 750px) {
        max-width: 354px;
      }
    }
    .text-container {
      padding: 0 30px 67px;
    }
    #pass-members-cta {
      display: flex;
      align-items: center;
      margin: 0 -22px;
      img {
        width: 100%;
        max-width: 32px;
      }
      p {
        margin-left: 15px;
        text-transform: uppercase;
        color: #122771;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.84px;
      }
    }
    .app-store-icons {
      margin-left: 20px;
      @media (min-width: 750px) {
        margin-left: -20px;
      }
    }
  }
  .step:not(#StartYourCharge) {
    @media (min-width: 750px) {
      h3 {
        margin-bottom: 20px;
      }
    }
  }
}

#InTheApp {
  @media (min-width: 750px) {
    #SwipeToStart {
      .text-container {
        margin-top: -40px;
      }
    }
  }
  .steps-container {
    padding-top: 50px;
  }
  #PlugInTheConnectorUntilItClicks {
    .text-container {
      @media (min-width: 750px) {
        margin-top: 16px;
      }
    }
  }
}

#InYourCar {
  .steps-container {
    padding-top: 50px;
  }
  #SelectAChargingStation {
    .text-container {
      @media (min-width: 750px) {
        margin-top: -30px;
      }
    }
  }
  #SelectChargingNumber {
    .text-container {
      @media (min-width: 750px) {
        margin-top: -120px;
      }
    }
  }
  #TapStartACharge {
    .text-container {
      @media (min-width: 750px) {
        margin-top: -50px;
      }
    }
  }
  #PlugIn {
    .text-container {
      @media (min-width: 750px) {
        margin-top: 20px;
      }
    }
  }
}

#Charge {
  .text-container {
    padding: 0 35px;
    @media (min-width: 750px) {
      padding: 0 0 0 55px;
    }
    ul {
      padding-left: 15px;
    }
  }
}

#StartYourCharge {
  .header {
    padding-left: 20px;
    @media (min-width: 750px) {
      padding-left: 55px;
    }
  }
}

.download-app-link {
  text-decoration: underline;
  color: black;
}

.mobile-app-banner {
  background-color: whitesmoke;
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 25px;
  border-radius: 8px;
  font-weight: $fw-regular;
  @media (min-width: 750px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -40px;
    margin-bottom: 50px;
  }
  .mobile-app-icon-container {
    align-items: center;
    display: flex;
    margin-bottom: 20px;

    @media (min-width: 750px) {
      margin-bottom: 0;
    }
    .mobile-app-icon {
      width: 64px;
      height: 64px;
      @media (min-width: 750px) {
        width: 96px;
        height: 96px;
      }
      img {
        width: 64px;
        height: 64px;
        @media (min-width: 750px) {
          width: 96px;
          height: 96px;
        }
      }
    }
    .mobile-app-text {
      max-width: 436px;
      margin-left: 15px;
      font-size: 16px;
      b {
        font-weight: 600;
      }
      @media (min-width: 750px) {
        margin-left: 55px;
        font-size: 24px;
      }
    }
  }
  .app-store-icons {
    text-align: center;
    @media (min-width: 750px) {
      display: flex;
      align-items: center;
      max-width: 375px;
    }
  }
}

.trademark {
  max-width: 317px;
  margin: 0 auto;
  display: block;
  color: black;
  font-style: italic;
}
::v-deep .dropdown-container {
  box-sizing: border-box;
  border: 0;
  padding: 15px;
  button:not(.main-heading):not(.sub-heading) {
    font-size: 18px;
    font-weight: $fw-medium;
  }
  h5 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px !important;
  }
  li {
    list-style: disc;
  }
}
</style>
