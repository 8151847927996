<template>
  <div id="charge-your-ev-tabs">
    <h1 id="tabs-title">Charging Your EV</h1>
    <div id="ev-tabs-container" role="tablist" ref="tablist">
      <button
        v-for="label in labels"
        :key="label.value"
        :id="`tab-${label.value}`"
        class="ev-tab"
        role="tab"
        :tabindex="value === label.value ? null : '-1'"
        :aria-controls="`tabpanel-${label.value}`"
        :aria-selected="value === label.value ? 'true' : 'false'"
        :class="{ 'active-tab': value === label.value }"
        @click="handleTabClick(label.value)"
      >
        <p v-if="label.value === 'InTheApp'">Recommended<br /></p>
        {{ label.text }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChargingYourEvTabs',
  props: ['value'],
  data() {
    return {
      labels: [
        { text: 'In the app', value: 'InTheApp' },
        { text: 'At the charger', value: 'AtTheCharger' },
        { text: 'In your car', value: 'InYourCar' },
      ],
    };
  },
  mounted() {
    this.$refs.tablist.addEventListener('keydown', (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          this.handleTabKeypress(e, this.getPreviousTab());
          break;

        case 'ArrowRight':
          this.handleTabKeypress(e, this.getNextTab());
          break;

        case 'Home':
          this.handleTabKeypress(e, this.labels[0].value);
          break;

        case 'End':
          this.handleTabKeypress(e, this.labels[this.labels.length - 1].value);
          break;

        default:
          break;
      }
    });
  },
  methods: {
    getPreviousTab() {
      const index = this.labels.findIndex((label) => label.value === this.value);
      if (index === 0) {
        return this.labels[this.labels.length - 1].value;
      }
      return this.labels[index - 1].value;
    },
    getNextTab() {
      const index = this.labels.findIndex((label) => label.value === this.value);
      if (index === this.labels.length - 1) {
        return this.labels[0].value;
      }
      return this.labels[index + 1].value;
    },
    /**
     * Send to the parent the clicked tab value
     * The parent will pass to TabContent, which will render the correct content
     */
    handleTabClick(value) {
      this.$emit('input', value);
    },
    handleTabKeypress(e, value) {
      this.handleTabClick(value);
      this.$refs.tablist.querySelector(`#tab-${value}`).focus();
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
#charge-your-ev-tabs {
  background-color: #0d1a3f;
  padding: 40px 18px 0;
  @media (min-width: 750px) {
    padding-top: 25px;
    padding: 40px 95px 0;
  }
}
#tabs-title {
  color: white;
  margin: 0;
  line-height: 1;
  margin-bottom: 40px;
  font-weight: 600;
  @media (min-width: 750px) {
    margin-bottom: 25px;
  }
}
#ev-tabs-container {
  display: inline-flex;
  max-width: 525px;
  position: relative;
  bottom: -1px;
}
.ev-tab {
  align-items: center;
  appearance: none;
  background-color: white;
  border: 0;
  border-radius: 5px 5px 0 0;
  color: #1f2555;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: Ubuntu, Arial, sans-serif;
  font-size: 16px;
  justify-content: center;
  line-height: 1.3;
  margin: 0 7px;
  min-height: 60px;
  opacity: 0.7;
  padding: 0 20px;
  text-align: center;
  p {
    margin: 0;
    font-size: 14px;
  }

  &:first-child {
    margin-left: 0;
  }
  &.active-tab {
    opacity: 1;
    font-weight: 600;
  }
  @media (min-width: 750px) {
    margin: 0 12px;
    min-width: 200px;
    font-size: 22px;
    letter-spacing: 0.45px;
  }
}
</style>
